<template>
  <el-dialog
    title="修改"
    :visible.sync="dialogVisible"
    width="520px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form ref="paramsFormRef" :rules="rules" label-width="120px" :model="paramsForm">
      <el-form-item :label="getType === 1 ? '检查表名称' : '复查表名称'" prop="name">
        <el-input v-model="paramsForm.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {editModel} from "api/admin/institution";

export default {
  data(){
    return{
      dialogVisible: false,
      submitLoading: false,
      paramsForm:{
        name: '',
        id: null
      },
      getType: 1,
      rules:{
        name:[
          { required: true, message: '请输入', trigger: 'blur' },
          { max: 20, message: '长度限制在20个字符以内', trigger: 'blur' }
        ],
      }
    }
  },
  methods:{
    open(row,type) {
      type == '1' ? this.getType = 1 : this.getType = 2
      this.dialogVisible = true;
      this.paramsForm.id = row.id
      this.paramsForm.name = row.name
    },
    onConfirm(){
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        const { id, name } = this.paramsForm;
        if(!name.trim()){
          this.$message.warning('请输入')
          return;
        }
        editModel({id, name}).then(res => {
          this.onCancel()
          this.$emit('ok', true);
        })
      });
    },
    onCancel(){
      this.dialogVisible = false
      this.$refs.paramsFormRef.resetFields()
    }
  }
}
</script>

<style scoped>

</style>
