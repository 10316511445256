<template>
  <el-dialog
    title="查看模板"
    :visible.sync="dialogVisible"
    width="890px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <div class="box">
      <img :src="imgSrc" class="pic" alt="模板图片" title="模板图片">
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onCancel">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getModelDetail } from '@/api/admin/institution'
export default {
  data(){
    return{
      dialogVisible: false,
      imgSrc: '',
    }
  },
  methods:{
    open(row) {
      getModelDetail({id: row.id}).then(res => {
        this.imgSrc = res.data.picUrl
        this.dialogVisible = true;
      })
    },
    onCancel(){
      this.imgSrc = ''
      this.dialogVisible = false
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog__body{
  padding: 0 20px;
}
.box{
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.pic{
  width:100%;
}
</style>
