<template>
  <div>
    <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;" @change="changeTable">
      <el-radio-button label="1">检查表</el-radio-button>
      <el-radio-button label="2">复查表</el-radio-button>
    </el-radio-group>
    <div class="table" >
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="80">
        </el-table-column>
        <el-table-column
          prop="name"
          :label="tabPosition == '1' ? '检查表名称': '复查表名称'"
          align="center"
          >
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="状态"
          >
          <template slot-scope="{ row }">
            <el-switch
              class="table-scope"
              v-model="row.status"
              :active-value="1"
              active-color="#13ce66"
              :inactive-value="2"
              inactive-color="#ff4949"
              @change="changeStatus(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="操作"
          width="180"
          fixed="right">
          <template slot-scope="{ row }">
            <div class="com-operation">
              <span class="edit-btn" @click="$refs.checkFormDialogRef.open(row,tabPosition)">修改</span>
              <el-divider direction="vertical"></el-divider>
              <span class="edit-btn" @click="checkModel(row)">查看模板</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination" v-if="tableData.length">
      <el-pagination
        :total="total"
        :current-page="queryParam.pageNo"
        :page-size="queryParam.pageSize"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, prev, pager, next, sizes"
      ></el-pagination>
    </div>
    <checkFormDialog ref="checkFormDialogRef" @ok="ok"></checkFormDialog>
    <checkModeDialog ref="checkModeDialogRef"></checkModeDialog>
  </div>
</template>

<script>
import checkFormDialog from "views/admin/checklist/checkFormDialog";
import checkModeDialog from "views/admin/checklist/checkModeDialog";
import { getModelList, editStatus } from "@/api/admin/institution"
export default {
  name: "ListMode",
  components:{
    checkFormDialog,checkModeDialog
  },
  created() {
    this.getModelLists()
  },
  data() {
    return {
      tabPosition: '1',
      loading: true,
      queryParam: {
        type: 1,
        pageSize: 10,
        pageNo:1
      },
      total: 0,
      tableData: [],
    };
  },
  methods:{
    // 获取模板列表
    getModelLists(){
      getModelList(this.queryParam).then(res => {
        this.tableData = res.data.rows
        this.total = res.data.totalRows
      }).finally(() => {
        this.loading = false
      })
    },
    // 切换检查表/复查表
    changeTable(){
      this.loading = true
      this.queryParam.type = this.tabPosition
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = 10
      this.getModelLists()
    },
    handleSizeChange(size) {
      this.queryParam.pageSize = size;
      this.queryParam.pageNo = 1;
      this.getModelLists()
    },
    handleCurrentChange(page) {
      this.queryParam.pageNo = page;
      this.getModelLists()
    },
    // 弹窗回调
    ok(flag){
      if(flag){
        this.getModelLists()
      }
    },
    // 冻结/解冻
    changeStatus(row){
      let obj = {
        id: row.id,
        status: row.status
      }
      editStatus(obj).then(() => {
        this.$message.success(row.status == 1 ? '解冻成功' : '冻结成功')
      }).finally(() => {
        this.getModelLists()
      })
    },
    checkModel(row){
      this.$refs.checkModeDialogRef.open(row)
    },
  }
}
</script>

<style lang="less" scoped>
.com-operation {
  cursor: pointer;
  .edit-btn {
    color: #1FB3F7;
  }
}
.pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
